$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

$border-radius:          .45rem !default;
$border-radius-lg:       .5rem !default;
$border-radius-sm:       .3rem !default;

$btn-border-radius:              $border-radius !default;
$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:           $border-radius-sm !default;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$light-blue: #00afe2 !default;
$dark-blue: #005f9a !default;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #333 !default;
$blue:     $light-blue !default; //HenF light blue
$indigo:   #6610f2 !default;
$purple:   #6f42c1 !default;
$pink:     #e83e8c !default;
$red:      #dc3545 !default;
$orange:   #fd7e14 !default;
$yellow:   #ffc107 !default;
$green:    #28a745 !default;
$teal:     #20c997 !default;
$cyan:     #17a2b8 !default;

$twitter:     #1da1f2 !default;
$facebook:    #3b5998 !default;
$pinterest:   #bd081c !default;
$linkedin:    #0077b5 !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary: $light-blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
  twitter: $twitter,
  facebook: $facebook,
  pinterest: $pinterest,
  linkedin: $linkedin,
  blue: $light-blue,
  darkblue: $dark-blue
) !default;

$navbar-light-color:                $white !default;
$navbar-light-hover-color:          $white !default;
$navbar-light-active-color:         $white !default;
$navbar-light-disabled-color:       rgba($white,.3) !default;
// $navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($white,1) !default;

$enable-rounded:            false !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

$spacer: 1rem !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Open Sans",  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

$font-size-base: 0.95rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem !default;
$font-size-sm:   .875rem !default;

$h1-font-size: 2.3rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.75rem !default;
$h4-font-size: 1.5rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;

$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$headings-font-weight: 300 !default;

$line-height-base: 1.8 !default;
$headings-line-height:   1.1 !default;



$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;


$alert-padding-y:             1rem !default;
$alert-padding-x:             1.25rem !default;
$alert-margin-bottom:         1rem !default;
// $alert-border-radius:         0 !default;
$alert-link-font-weight:      $font-weight-bold !default;
$alert-border-width:          0 !default;



// $input-border-radius:            0 !default;
// $input-border-radius-lg:         0 !default;
// $input-border-radius-sm:         0 !default;



$table-cell-padding:            .75rem !default;
$table-cell-padding-sm:         .5rem !default;

$table-bg:                      transparent !default;
$table-accent-bg:               rgba($dark-blue,.05) !default;
$table-hover-bg:                rgba($black,.075) !default;
$table-active-bg:               $table-hover-bg !default;

$table-border-width:            0 !default;
$table-border-color:            $gray-200 !default;

$table-head-bg:                 $gray-200 !default;
$table-head-color:              $gray-700 !default;

$table-inverse-bg:              $gray-900 !default;
$table-inverse-accent-bg:       rgba($white, .05) !default;
$table-inverse-hover-bg:        rgba($white, .075) !default;
$table-inverse-border-color:    lighten($gray-900, 7.5%) !default;
// $table-inverse-color:           $body-bg !default;
