.home{
  .navbar{
    background-color: transparent;
    .navbar-toggler {
      border-color: darken($dark-blue, 15%);
      .navbar-toggler-icon{
        $toggler-second: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{darken($dark-blue, 15%)}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
        background-image: $toggler-second;
      }
    }
    &.show{
      background-color: darken($dark-blue, 15%);
      .navbar-toggler {
        border-color: $white;
        .navbar-toggler-icon{
          $toggler-second: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
          background-image: $toggler-second;
        }
      }
    }
    @include media-breakpoint-up(md) {
      background-color: darken($dark-blue, 15%);
    }

  }
}

.navbar{
  background-color: darken($dark-blue, 15%);
  padding: 1rem;
  font-weight: 300;

  .navbar-brand{
    @include media-breakpoint-up(md) {
      position: absolute;
    }
  }

  .navbar-nav{

    &.right{
      border-top: 1px solid $white;
      margin-top: 20px;
      padding-top: 20px;
    }

    &.center{
      padding-top: 20px;
      .center{
        padding-top: 0;
      }
    }

    .nav-item{

      &.dropdown{
        .dropdown-menu{
          background: $light-blue;
          border-radius: 0;
          margin: 0 -1rem;
          padding: 0 1rem;
        }
      }


      @include media-breakpoint-up(md) {

        &.dropdown{
          position: initial;
          .dropdown-menu{
            border-radius: 0;
            margin: 0;
            border: 0;
            font-weight: normal;
            width: 100%;
            background: $light-blue;

            @include media-breakpoint-up(md) {
              display: flex;
              padding: 0;
              overflow: hidden;
              height: 0;
              transition: height 0.15s ease-out;
            }
            @include media-breakpoint-up(md) {
              &.show{
                display: flex;
                height: 75px;
              }
            }

            a{
              color: #fff;
            }
            li{
              display: flex;
              margin: auto;
              &.active{
                a{
                  color: darken($dark-blue, 15%);
                  // color: #fff;
                  font-weight: 900;
                }
              }
            }
          }
        }

      }


    }

    @include media-breakpoint-up(md) {
      &.center{
        padding-top: 0;
        .nav-link{
          padding-right: .8rem;
          padding-left: .8rem;
        }
        .dropdown-toggle {
          &::after {
            // margin-left: 0;
            vertical-align: $caret-width * .65;
          }
        }
      }

      &.right{
        position: absolute;
        font-size: .8rem;
        right: $grid-gutter-width/2;
        border: 0;
        margin-top: 0;
        padding-top: 0;
      }
    }

    .outline-white{
      @include media-breakpoint-up(md) {
        margin: auto;
        padding-right: .5rem;
        padding-left: .5rem;
        a{
          border: solid 1px $white;
          border-radius: $border-radius;
          padding-top: .25rem;
          padding-bottom: .25rem;
        }
      }
    }
  }


}
