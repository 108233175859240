.single{
  article{
    > header{
      background-size: cover;
      background-position: 50%;
      .container{
        .row{
          height: 500px;
          h1{
            font-size: 4rem;
            text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
    footer{
      .more_posts{
        .more_item{
          height: 300px;
          background-size: cover;
          background-position: 50%;
        }
      }
    }
  }
}
