@media all and (-ms-high-contrast:none) {
  .navbar-brand {
    top: 12px;
  }


  .dropdown {
    .dropdown-menu {
      ul {
        margin-top: 16px;
      }
    }
  }

  #menu-rechts {
    top: 18px;
  }

  .card-img-top {
    flex-shrink: 0;
    height: auto;
  }

  @include media-breakpoint-up(md) {
    .owl-content {
      .align-self-end {
        margin-top: 650px;
      }
    }
  }
}

* {
  box-sizing: border-box;
  outline: none;
}



.logo{
  margin-left: -10px;
}

.btn-outline-white {
  @include button-outline-variant(#fff, $dark-blue);
}


.alert{
  p{
    &:last-child(){
      margin: 0;
    }
  }
  a{
    color: inherit;
    // text-decoration: underline;
    font-weight: 900;
  }
}


form{
  &.wpcf7-form{
    .form-group{
      label{
        .required{
          color: $red;
        }
      }
      .help-block{
        color: $dark-blue;
        font-size: .8rem;
        &.wpcf7-not-valid-tip{
          color: $red;
        }
      }
      &.has-error{
        input{
          border-color: $red;
        }
      }
    }
  }
}

table{
  @extend .table;
  @extend .table-responsive;
  @extend .table-striped;
  @extend .table-sm;
  font-size: 0.8rem;
  td{
    color: $dark-blue;
  }
  th{
    background-color: $dark-blue;
    color: $white;
    font-weight: normal;
    &:nth-of-type(even){
      background-color: lighten($dark-blue, 3%);
    }
  }

  tr{
    &:nth-of-type(even){
      th{
        background-color: lighten($dark-blue, 3%);
      }
    }
  }
}


/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('../fonts/open-sans-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/open-sans-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
       url('../fonts/open-sans-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}




.wpcf7 {
  .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0;
  }
  .form-inline img.ajax-loader {
    display: inline;
  }
  .ajax-loader {
    visibility: hidden;
    display: inline-block;
    background-image: url('/wp-content/plugins/contact-form-7/images/ajax-loader.gif');
    width: 16px;
    height: 16px;
    border: none;
    padding: 0;
    margin: 0 0 0 4px;
    vertical-align: middle;
    &.is-active {
      visibility: visible;
    }
  }
  div.ajax-error, .wpcf7-display-none {
    display: none;
  }
  .placeheld {
    color: #888;
  }
  .wpcf7-recaptcha iframe {
    margin-bottom: 0;
  }
  input[type="file"] {
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }
  .form-inline .form-group {
    max-width: 250px;
  }
  .input-group-addon {
    img {
      height: 100%;
      width: auto;
      max-width: none !important;
      border-radius: 5px;
    }
    &.input-group-has-image {
      padding: 0;
    }
  }
}
