// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.form-check-input:only-child {
  position: absolute;
  top: 3px;
}

// Gforms

.btn{
  cursor: pointer;
}

.ginput_container{
  &_text{
    input{
      @extend .form-control;
    }
  }
  &_textarea{
    textarea{
      @extend .form-control;
    }
  }
  &_email{
    input{
      @extend .form-control;
    }
  }
}


.gform_required_legend{
  display: none;
}

.contact_wrapper{
  .gform_body{
    .gform_fields{
      .gfield_label{
        font-weight: $font-weight-base;
      }
    }
  }
}

.offers-form_wrapper{
  .gform_title{
    margin-bottom: $spacer * 2.5;
  }
  .gform_fields{
    margin-bottom: $spacer;
    .gfield_label{
      margin-bottom: $spacer * .75;
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
    }
    .gchoice{
      margin-bottom: $spacer *.5;
    }
    .gsection{
      border-bottom: none;
      .gsection_title{
        font-size: .95rem;
        font-weight: $font-weight-bold;
        margin-bottom: 0;
        }
    }
  }
  .gfield--width-half{
    .gfield_label{
      font-weight: $font-weight-base;
    }
  }
  .email{
    .gfield_label{
      font-weight: $font-weight-base;
    }
  }
}