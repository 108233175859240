.home{
  main{
    section{
      .row{
        > div{
          &.content{
            padding-top: 75px;
            @include media-breakpoint-up(md) {
              padding-top: 120px;
            }
          }
        }
      }
    }
  }
}


main{
  section{
    > .row{
      > div{
        min-height: 600px;
        @include media-breakpoint-up(md) {
          min-height: 100vh;
        }
        &.content{
          padding: $grid-gutter-width*1.5;
          padding-top: 120px;
        }
        &.slider-wrapper{
          background: $light-blue;
          .owl-carousel{
            height: 100%;
            margin-left: 0;
            .owl-stage-outer{
              height: 100%;
              .owl-stage{
                height: 100%;
                .owl-item{
                  height: 100%;
                  .owl-content{
                    color: #fff;
                    min-height: 100%;
                    background-position: 50%;
                    background-size: cover;
                    padding: $grid-gutter-width*2;
                    position: relative;
                    &:after{
                      position: absolute;
                      top: 0;
                      right: 0;
                      bottom: 0;
                      left: 0;
                      content: '';
                      display: block;
                    }
                    &.light{
                      &:after{
                        @include gradient-y(rgba(255, 255, 255, 0), $light-blue,  10%, 100% );
                        @include media-breakpoint-up(md) {
                          @include gradient-y(rgba(255, 255, 255, 0), $light-blue,  50%, 100% );
                        }
                      }
                    }
                    &.dark{
                      &:after{
                        @include gradient-y(rgba(255, 255, 255, 0), $dark-blue,  10%, 100% );
                        @include media-breakpoint-up(md) {
                          @include gradient-y(rgba(255, 255, 255, 0), $dark-blue,  50%, 100% );
                        }
                      }
                    }
                    > div{
                      position: relative;
                      z-index: 1;
                    }
                    a{
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.bg-photo{
  background-size: cover;
  background-position: 50%;
  position: relative;
  .d-flex{
    position: relative;
    z-index: 1;
  }
  &.black,
  &.blue,
  &.darkblue{
    &:after{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      display: block;
    }
  }
  &.blue{
    &:after{
      @include gradient-y(rgba(255, 255, 255, 0), $light-blue,  10%, 100% );
      @include media-breakpoint-up(md) {
        @include gradient-y(rgba(255, 255, 255, 0), $light-blue,  50%, 100% );
      }
    }
  }
  &.darkblue{
    &:after{
      @include gradient-y(rgba(255, 255, 255, 0), $dark-blue,  10%, 100% );
      @include media-breakpoint-up(md) {
        @include gradient-y(rgba(255, 255, 255, 0), $dark-blue,  50%, 100% );
      }
    }
  }
  &.black{
    &:after{
      @include gradient-y(rgba(255, 255, 255, 0), $black,  10%, 100% );
      @include media-breakpoint-up(md) {
        @include gradient-y(rgba(255, 255, 255, 0), $black,  50%, 100% );
      }
    }
  }
}

.home{
  main{
    section{
      .row{
        > div{
          &.content{
            ul{
              padding-left: 0;
              list-style: none;
              li{
                position: relative;
                padding-left: 13px;
                &:before {
                  position: absolute;
                  left: 0;
                  content: "...";
                }
              }
            }
          }
        }
      }
    }
  }
}


.archive{
  &.post-type-archive-team{

    @include media-breakpoint-up(md) {
      .ml-auto{
        margin-left: 0 !important;
      }
      main{
        .bg-photo{
          position: fixed;
          right: 0;
        }
      }

    }
    @include media-breakpoint-up(lg) {
      .ml-auto{
        margin-left: 8.3333333333% !important;
      }
    }

    .teammember{
        small{
          line-height: 0.8rem;
          float: left;
        }
        .card-title{
          font-weight: 700;
        }
      }
    }

}
